import { Injectable, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { AbstractControl } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import * as startOfDay from 'date-fns/start_of_day';
import * as firebase from 'firebase/app';
import * as groupBy from 'lodash.groupby';

import {
  CurrentUser, FieldValue, Item, ItemLine, Order, OrderGroup, Platform, Purchase, PurchaseGroup,
  QuantityStatus, ShippingCompany, Store, User, Timestamp, Transaction, Variant
} from '../interfaces/interfaces';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  readonly stats = ['quantity', 'pending', 'shipped', 'delivered', 'returned', 'cancelled'];

  readonly plans = {
    start: {
      itemsLimit: 5
    },
    pro: {
      itemsLimit: 50
    },
    business: {
      itemsLimit: 100
    }
  };

  readonly emptyQuantity = {
    quantity: 0,
    pending: 0,
    shipped: 0,
    delivered: 0,
    returned: 0,
    cancelled: 0
  };

  constructor(
    private alertCtrl: AlertController,
    private auth: AngularFireAuth,
    private db: AngularFirestore,
    private http: HttpClient,
    private storage: AngularFireStorage,
    private translate: TranslateService
  ) {}

  closeKeyboard() {
    (document.activeElement as any).blur();
  }

  compareWithIds(a: any, b: any): boolean {
    if (a && b) {
      return a.id === b.id;
    }

    return a === b;
  }

  getBuyLink(itemUrl: string) {
    return `https://buy.savelist.co/?url=${encodeURIComponent(itemUrl)}&utm_source=sellmo&utm_medium=web`;
  }

  async getCountries(): Promise<any[]> {
    const lang = this.translate.currentLang;
    return this.http.get<any[]>(`./assets/i18n/countries-${lang}.json`).toPromise();
  }

  async getCurrencies(): Promise<any[]> {
    const lang = this.translate.currentLang;
    return this.http.get<any[]>(`./assets/i18n/currencies-${lang}.json`).toPromise();
  }

  async getCurrentUser(): Promise<CurrentUser> {
    return this.takeFirst(this.auth.user);
  }

  getDownloadUrl(path: string): Observable<any> {
    if (!path) {
      return null;
    }

    return this.storage.ref(path).getDownloadURL();
  }

  getGroupDate(orderOrPurchase: Order | Purchase) {
    const date = (orderOrPurchase as Order).orderDate || (orderOrPurchase as Purchase).purchaseDate;
    return startOfDay(this.timestampToDate(date)).toISOString();
  }

  async getPlatforms(): Promise<Observable<Platform[]>> {
    const currentUser = await this.getCurrentUser();

    return this.db.collection<Platform>(
      `users/${currentUser.uid}/platforms`, ref => ref.orderBy('name')
    ).valueChanges();
  }

  getItemLineQuantityStatus(itemLine: ItemLine): QuantityStatus {
    if (!itemLine || !itemLine.item) {
      return this.emptyQuantity;
    }

    return {
      itemId: itemLine.item.id,
      variantId: itemLine.item.variant.id,
      ...this.emptyQuantity,
      quantity: itemLine.quantity,
      [itemLine.status]: itemLine.quantity
    };
  }

  private async getItemQuantityChanges(
    collection: 'orders' | 'purchases',
    newDoc: Order | Purchase,
    transaction: Transaction
  ): Promise<QuantityStatus[]> {
    const currentUser = await this.getCurrentUser();
    const oldDocRef = this.db.doc<Order|Purchase>(`users/${currentUser.uid}/${collection}/${newDoc.id}`);
    const oldDocSnapshot = await transaction.get(oldDocRef.ref);
    const oldDoc: Order | Purchase = oldDocSnapshot.data() as any;

    const oldQuantities = oldDoc && oldDoc.items.map(itemLine => this.getItemLineQuantityStatus(itemLine)) || [];
    const newQuantities = newDoc.items.map(itemLine => this.getItemLineQuantityStatus(itemLine));

    // Sum all old and new quantities for every variant
    const oldQuantitiesSum = this.sumQuantityStatusesByVariant(oldQuantities);
    const newQuantitiesSum = this.sumQuantityStatusesByVariant(newQuantities);

    // Compare new quantities to old quantities
    const quantityChanges: QuantityStatus[] = newQuantitiesSum.map(newQuantitySum => {
      const oldQuantitySum = oldQuantitiesSum.find(sum => sum.variantId === newQuantitySum.variantId) || { ...this.emptyQuantity };
      const changes = { ...newQuantitySum };
      this.subtractStats(changes, oldQuantitySum);
      return changes;
    });

    // Check if old quantities have been removed
    oldQuantitiesSum.forEach(oldQuantitySum => {
      const newQuantitySum = newQuantitiesSum.find(sum => sum.variantId === oldQuantitySum.variantId);

      if (newQuantitySum) {
        return;
      }

      quantityChanges.push({
        itemId: oldQuantitySum.itemId,
        variantId: oldQuantitySum.variantId,
        quantity: -oldQuantitySum.quantity,
        pending: -oldQuantitySum.pending,
        shipped: -oldQuantitySum.shipped,
        delivered: -oldQuantitySum.delivered,
        returned: -oldQuantitySum.returned,
        cancelled: -oldQuantitySum.cancelled
      });
    });

    // Filter out lines without quantity changes
    const filteredQuantityChanges = quantityChanges.filter(changes =>
      changes.quantity ||
      changes.pending ||
      changes.shipped ||
      changes.delivered ||
      changes.returned ||
      changes.cancelled
    );

    return filteredQuantityChanges;
  }

  async getUser(): Promise<User> {
    const currentUser = await this.getCurrentUser();
    const userRef = this.db.doc<User>(`users/${currentUser.uid}`);
    return this.takeFirst(userRef.valueChanges());
  }

  async getShippingCompanies(): Promise<Observable<ShippingCompany[]>> {
    const currentUser = await this.getCurrentUser();

    return this.db.collection<ShippingCompany>(
      `users/${currentUser.uid}/shipping`,
      ref => ref.orderBy('name')
    ).valueChanges();
  }

  async getStores(): Promise<Observable<Store[]>> {
    const currentUser = await this.getCurrentUser();

    return this.db.collection<Store>(
      `users/${currentUser.uid}/stores`,
      ref => ref.orderBy('name')
    ).valueChanges();
  }

  groupOrdersByDate(orders: Order[]): OrderGroup[] {
    const dates = groupBy(orders, this.getGroupDate.bind(this));
    return Object.keys(dates).map(date => ({ date, orders: dates[date] }));
  }

  groupPurchasesByDate(purchases: Purchase[]): PurchaseGroup[] {
    const dates = groupBy(purchases, this.getGroupDate.bind(this));
    return Object.keys(dates).map(date => ({ date, purchases: dates[date] }));
  }

  isMobile(): boolean {
    return this.matchMedia(window, '(any-pointer: coarse)');
  }

  matchMedia(win: Window, query: string): boolean {
    return win.matchMedia(query).matches;
  }

  async scrollToElement(element: ElementRef) {
    element.nativeElement.scrollIntoView(true);
  }

  serverTimestamp(): FieldValue {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  async setDefaultForeignCurrency(control: AbstractControl) {
    const user = await this.getUser();
    control.setValue(user.foreignCurrency);
  }

  async setDefaultLocalCurrency(control: AbstractControl) {
    const user = await this.getUser();
    control.setValue(user.localCurrency);
  }

  async setItemQuantityChanges(
    collection: 'orders' | 'purchases',
    newDoc: Order | Purchase,
    transaction: Transaction
  ) {
    const currentUser = await this.getCurrentUser();
    const quantityChanges = await this.getItemQuantityChanges(collection, newDoc, transaction);
    const itemIds = [];

    quantityChanges.forEach(changes => {
      if (!itemIds.includes(changes.itemId)) {
        itemIds.push(changes.itemId);
      }
    });

    const getItemsPromises = itemIds.map(itemId => {
      const itemRef = this.db.doc<Item>(`users/${currentUser.uid}/items/${itemId}`);
      return transaction.get(itemRef.ref);
    });

    const snapshots = await Promise.all(getItemsPromises);

    snapshots.forEach(snapshot => {
      if (!snapshot.exists) {
        throw new Error(`Document "${snapshot.ref.path}" doesn't exist`);
      }

      const item: Item = snapshot.data() as any;
      const itemChanges = quantityChanges.filter(changes => changes.itemId === item.id);

      if (!itemChanges.length) {
        throw Error(`Changes for Item "${item.id}" not found`);
      }

      itemChanges.forEach(changes => {
        const variant = item.variants.find(itemVariant => itemVariant.id === changes.variantId);

        if (!variant) {
          throw Error(`Variant "${changes.variantId}" not found`);
        }

        if (collection === 'orders') {
          this.setOrderQuantityChanges(item, variant, changes);
        } else if (collection === 'purchases') {
          this.setPurchaseQuantityChanges(item, variant, changes);
        }
      });

      transaction.update(snapshot.ref, item);
    });
  }

  private setOrderQuantityChanges(item: Item, variant: Variant, changes: QuantityStatus) {
    item.quantity -= changes.quantity - changes.returned - changes.cancelled;
    this.sumStats(item.stats.orders, changes);

    variant.quantity -= changes.quantity - changes.returned - changes.cancelled;
    this.sumStats(variant.stats.orders, changes);
  }

  private setPurchaseQuantityChanges(item: Item, variant: Variant, changes: QuantityStatus) {
    item.quantity += changes.delivered;
    item.pending += changes.pending;
    item.shipped += changes.shipped;
    this.sumStats(item.stats.purchases, changes);

    variant.quantity += changes.delivered;
    variant.pending += changes.pending;
    variant.shipped += changes.shipped;
    this.sumStats(variant.stats.purchases, changes);
  }

  async showError(errorCode: string): Promise<void> {
    if (!errorCode || typeof errorCode !== 'string') {
      return;
    }

    console.error(errorCode);
    const errorKey = `ERRORS.${errorCode.toUpperCase()}`;

    const i18n = await this.translate.get([
      'APP.OK',
      'ERRORS.ERROR',
      errorKey
    ]).toPromise();

    const alert = await this.alertCtrl.create({
      header: i18n['ERRORS.ERROR'],
      message: i18n[errorKey],
      buttons: [{
        text: i18n['APP.OK']
      }]
    });

    return alert.present();
  }

  private sumQuantityStatusesByVariant(quantityStatuses: QuantityStatus[]): QuantityStatus[] {
    const quantitySumByVariant: QuantityStatus[] = [];

    quantityStatuses.forEach(quantityStatus => {
      const variantQuantitySum = quantitySumByVariant.find(
        quantitySum => quantitySum.variantId === quantityStatus.variantId
      );

      if (!variantQuantitySum) {
        return quantitySumByVariant.push(quantityStatus);
      }

      this.sumStats(variantQuantitySum, quantityStatus);
    });

    return quantitySumByVariant;
  }

  private sumStats(src: any, sum: any): void {
    this.stats.forEach(status => src[status] += sum[status]);
  }

  private subtractStats(src: any, sub: any): void {
    this.stats.forEach(status => src[status] -= sub[status]);
  }

  takeFirst<T>(observable: Observable<T>): Promise<T> {
    return observable.pipe(take(1)).toPromise();
  }

  timestampToDate(timestamp: Timestamp | Date): Date {
    if (!timestamp) {
      return null;
    }

    return (timestamp as Timestamp).toDate();
  }

  trackByDate(_: number, item: any): string {
    return item && item.date;
  }

  trackById(_: number, item: any): string {
    return item && item.id;
  }
}
