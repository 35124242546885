import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule) },
  { path: 'not-found', loadChildren: () => import('./pages/not-found/not-found.module').then(m => m.NotFoundPageModule) },
  { path: 'reset', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule) },
  { path: 'signup', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule) },
  { path: '', loadChildren: () => import('./pages/split-pane/split-pane.module').then(m => m.SplitPanePageModule) },
  { path: '**', redirectTo: '/not-found', pathMatch: 'full' }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
