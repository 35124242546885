import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatIconRegistry } from '@angular/material/icon';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {
  private currentLang = 'en';

  constructor(
    private auth: AngularFireAuth,
    private matIconRegistry: MatIconRegistry,
    private translate: TranslateService,
    private sanitizer: DomSanitizer
  ) {
    const browserLang = this.translate.getBrowserLang();

    if (['en', 'ja'].includes(browserLang)) {
      this.currentLang = browserLang;
    }

    this.translate.setDefaultLang('en');
    this.translate.use(this.currentLang);
  }

  ngOnInit() {
    this.auth.setPersistence('local');
    this.auth.useDeviceLanguage();

    this.matIconRegistry.addSvgIconInNamespace(
      'ionic',
      'open',
      this.sanitizer.bypassSecurityTrustResourceUrl('/assets/images/open.svg')
    );

    this.matIconRegistry.addSvgIconInNamespace(
      'ionic',
      'close',
      this.sanitizer.bypassSecurityTrustResourceUrl('/assets/images/close.svg')
    );
  }
}
